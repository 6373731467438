.mediaDetails {
  text-align: center;
  padding: 1rem;
}

.certification {
  border: 1px solid rgba(255, 255, 255, 0.6);
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 0px 0.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.listRow {
  display: flex;
  flex-wrap: nowrap;
  overflow-y: auto;
  padding: 1rem 0;
  gap: 1rem;
  color: #000;
  justify-content: flex-start;
  align-items: stretch;
}

.figureImg {
  border-radius: var(--bs-border-radius);
}

.fluidMedia {
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 */
  height: 0;
  overflow: hidden;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    border-radius: var(--bs-border-radius);
  }
}


.playIconWrapper {
  i {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.8rem;
    background: rgba(0, 0, 0, 0.7);
    padding: 0 1rem;
    font-size: 5rem;
  }
}
