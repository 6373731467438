.fluidMedia {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden;
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  background-color: #000;

  > iframe {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.figure {
  position: relative;
  text-align: center;

  img{
    border-radius: var(--bs-border-radius);
  }
}