.modalTitle {
  background-color: #000;
  color: #fff;
  border-bottom: transparent !important;
}

.fluidMedia {
  min-height: 85vh;
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 */
  height: 0;
  overflow: hidden;
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  background-color: #000;

  > div, > iframe {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
  }
}