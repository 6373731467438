body, #root {
  background: #000;
  color: #fff;
}

.movie-app {
  width: 90vw;
  margin: 0 auto;
}

.modal-xl {
  max-width: 75vw;
}
.modal-backdrop.show {
  opacity: 0.8;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--bs-gray-dark);
  border: 2px solid var(--bs-gray-dark);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #000;
}
::selection {
  background-color: var(--bs-gray-dark);
  color: var(--bs-gray-dark);
}

.motion-box {
  width: 100%;
}
