.carouselCaption {
  text-align: center;
  left: 0;
  top: 0;
  right: initial;
  bottom: initial;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding: 0 1rem;
}

.carouselFigure {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)); */
  background: linear-gradient(to right, black, transparent 50%, black 100%);
  box-shadow:  0px 5px 30px 5px rgb(0 0 1) inset;
  /* 0px 0px 20px 15px rgb(0 0 1) inset;  */
  /*15px 0px 20px 10px rgb(0 0 1) inset;*/
}

.figure {
  position: relative;
}

.figureImg {
  width: 14rem;
  border-radius: var(--bs-modal-inner-border-radius);
  margin: 0 auto;
  border-radius: var(--bs-border-radius);
}

.overview {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
